import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Skeleton, message, Modal, Table, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./ListUser.module.css";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import axios from "axios";
import { BASE_API_URL } from "../../../constant/url";
const { confirm } = Modal;

function ListUser() {
  const [loading, setLoading] = useState(true);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [getAllListAdmin, setGetAllListAdmin] = useState([]);
  const [startIndex, setStartIndex] = useState(1);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Admin Name",
      dataIndex: "fullname",
      width: "30%",
      key: "fullname",
    },
    {
      title: "Admin Email",
      dataIndex: "email",
      width: "30%",
      key: "email",
    },
    {
      title: "NPK",
      dataIndex: "NPK",
      width: "30%",
      key: "NPK",
    },
    {
      title: "Admin Role",
      dataIndex: "role",
      width: "30%",
      key: "role",
    },

    {
      title: "Action",
      key: "aksi",
      render: (_, record) => {
        if (getAllListAdmin.length >= 1) {
          return (
            <div className={styles.btngroup}>
              {/* <NavLink to={`/admin/detail-admin/${record.id}`}>
                <Tooltip placement="bottom" title={`Click to view admin`}>
                  <AiFillEye className={styles.iconActionView} />
                </Tooltip>
              </NavLink>
              <NavLink
                to={`/admin/edit-admin/${record.id}`}
                className={styles.btnEditCustomer}
              >
                <AiFillEdit />
              </NavLink> */}
              <button
                className={styles.btnDeleteAdmin}
                onClick={() => deleteAdmin(record.id)}
              >
                <AiFillDelete />
              </button>
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/auth/list-user`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListAdmin(
          response.data.data.filter((admin) => admin.role === "user")
        );
        console.log(setGetAllListAdmin, "data");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //delete admin
  const deleteAdmin = (value) => {
    confirm({
      title: "Are you sure you want to delete the admin ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/auth/deleteadmin/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete admin successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Admin Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() {},
    });
  };

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={styles.main}>
          <div>
            <Table
              bordered
              dataSource={getAllListAdmin}
              columns={columns}
              pagination={{
                pageSize: 10,
                onChange: (page, pageSize) => {
                  setStartIndex((page - 1) * pageSize + 1);
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ListUser;
