import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./styles/PrintDO.module.css";

import { Input, Modal, Select, Skeleton, Tabs, message } from "antd";
import {
  createDeliveryOrder,
  fetchDeliveryOrder,
  fetchDeliveryOrderById,
} from "../../services/DO";
import { getCurrentDate } from "../../helper/formatter";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAPIError } from "../../helper/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { updateJobStatus } from "../../services/Jobs";
import { BASE_API_URL } from "../../constant/url";
import axios from "axios";
import { KieLogo } from "../../assets";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
const { confirm } = Modal;

function PrintDO({ listAttn, jobStatus, company, jobNumber }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") || "do"
  );
  const [inventories, setInventories] = useState([]);
  const params = useParams();
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [loadingGenerateDO, setLoadingGenerateDO] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [messageVal, setMessageVal] = useState("");
  const [showPrintComponent, setShowPrintComponent] = useState(false);
  const [deliveryorder, setDeliveryOrder] = useState({
    jeniskendaraan: "",
    nomorkendaraan: "",
  });

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const handleChange = (event) => {
    setDeliveryOrder({
      ...deliveryorder,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/spendings/detail-spending/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
            message.error("Your session has expired. Please login again.");
          } else if (error.response.status == 404) {
            // Handle 404 error
          } else if (error.response.status === 403) {
            message.error("Access denied");
          } else {
            message.error("Unknown Error");
          }
        } else {
          // Handle other types of errors here
          console.log(error);
        }
      });
  }, []);

  //handle change
  // const handleChange = (event) => {
  //   const Jobnumber = event.target.value.toUpperCase();
  //   setadmin({
  //     ...admin,
  //     job_no: Jobnumber,
  //   });
  // };

  //handle input
  const handleInsertDeliveryOrder = async () => {
    setLoading(true);

    try {
      const payload = {
        jeniskendaraan: deliveryorder.jeniskendaraan,
        nomorkendaraan: deliveryorder.nomorkendaraan,
      };

      const response = await axios.put(
        `${BASE_API_URL}/v1/spendings/spendings/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Delivery Order Berhasil Dibuat");
      setLoading(false);
      // setTimeout(() => {
      //   navigate(`/pengeluaran/detail-pengeluaran/{id}`);
      // }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const inventoryItem = getAllInventory.find(
    (inventory) =>
      String(inventory.id) === String(getAllSpending.inventories_id)
  );
  const inventoryNameToShow = inventoryItem ? inventoryItem.inventoryname : "";

  // const handlePrint = () => {
  //   const printWindow = window.open("", "_blank");
  //   printWindow.document.write("<html><head><title>Cetak</title>");

  //   const containerWrapper = document.querySelector(
  //     `.${styles.containerWrapper}`
  //   );
  //   printWindow.document.write(containerWrapper.outerHTML);

  //   // Selesaikan pembuatan halaman cetakan
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   // Cetak halaman
  //   printWindow.print();

  //   // Tutup jendela cetakan setelah pencetakan selesai
  //   printWindow.onafterprint = () => {
  //     printWindow.close();
  //   };
  // };

  return (
    <div className={styles.wrapper}>
      <>
        <div
          className={styles.menu}
          style={{
            height: "auto",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 1px 6px 0 rgba(49, 53, 59, 0.12)",
            padding: "16px !important",
            marginTop: "24px",
          }}
        >
          <div
            className={styles.containerWrapper}
            style={{ border: "1px solid black" }}
          >
            {/* Wrapper Header */}
            <div
              className={styles.containerHeader}
              style={{
                display: "flex",
                flexDirection: "column",
                // borderBottom: "1px solid black",
              }}
            >
              <div
                className={styles.wrapperHeader}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  className={styles.imageLogo}
                  style={{
                    width: "110px",
                    height: "80px",
                    alignItems: "center",
                  }}
                  src={KieLogo}
                  alt=""
                />

                <h3
                  className={styles.wrapperHeaderTitle}
                  style={{
                    alignItems: "center",
                    marginLeft: "20px",
                    textAlign: "center",
                  }}
                >
                  PT. Kaltim Industrial Estate
                </h3>
                <img
                  className={styles.imageLogo}
                  style={{
                    width: "110px",
                    height: "80px",
                    alignItems: "center",
                  }}
                  src={KieLogo}
                  alt=""
                />
              </div>
              <div
                className={styles.wrapperHeader1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3> Delivery Order</h3>
              </div>
              <div
                className={styles.wrapperHeader2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>Concrete</h3>
              </div>
            </div>
            {/* Wrapper Body 1 */}
            <div
              className={styles.wrapperBody1}
              style={{
                //  marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div className={styles.mainLeftData} style={{ flex: 1 }}>
                  <p className={styles.titleDetail}>Hari & Tanggal</p>
                </div>
                <div className={styles.mainRightData} style={{ flex: 3 }}>
                  <p className={styles.textDetail}>: Sabtu, 00 Desember 2000</p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Nomor Kendaraan</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>: KT 2908 AB</p>
                </div>
              </div>
            </div>
            <div className={styles.wrapperBody2}>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Lokasi Proyek</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>: Bontang</p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Nama Pemesan</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>: Ranlog</p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Batching Plant</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>: 11111</p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>NO Permintaan</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>: 12312313123</p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              ></div>
            </div>
            <table
              className={styles.tableBody}
              style={{
                width: "95%",
                margin: "auto",
                marginTop: "30px",
                border: "1px solid black",
              }}
            >
              <tr
                style={{
                  border: "1px solid black",
                }}
              >
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  colSpan="4"
                >
                  Waktu
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  rowSpan="2"
                >
                  Concrete Type
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  rowSpan="2"
                >
                  Volume
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  rowSpan="2"
                >
                  Semen Type
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  rowSpan="2"
                >
                  Ket
                </th>
              </tr>
              <tr
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Berangkat
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Tiba
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Tuang
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Selesai
                </th>
              </tr>
              <tr
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  height: "100px",
                }}
              >
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Isi Berangkat
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  isi Tiba
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  isi Tuang
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  isi Selesai
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Isi Concrete Type
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  isi Volume
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  isi semen type
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  isi ket
                </td>
              </tr>
            </table>
            {/* wrapper keterangan */}
            <div className={styles.wrapperBody2}>
              <div
                className={styles.mainDetailData}
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  marginTop: "30px",
                }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Keterangan</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : ......................................
                  </p>
                </div>
              </div>
            </div>
            {/* Wrapper Footer */}
            <div
              className={styles.wrapperFooter}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginLeft: "60px",
                marginRight: "60px",
                marginTop: "30px",
                alignContent: "center",
              }}
            >
              <div>
                <span>Mengetahui</span>
              </div>
              <div>
                <span>Driver</span>
              </div>
              <div>
                <span>Pengawas Proyek / Penerima</span>
              </div>
            </div>
            <div
              className={styles.wrapperFooter2}
              style={{
                display: "flex",
                marginTop: "80px",
                marginLeft: "60px",
                marginRight: "60px",
                alignItems: "center",
                alignContent: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div>
                <span>
                  (...........................................................)
                </span>
              </div>
              <div>
                <span>
                  (...........................................................)
                </span>
              </div>
              <div>
                <span>
                  (...........................................................)
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default PrintDO;
