import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Skeleton, message, Modal, Table, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styles from "./HistoryBarangMasuk.module.css";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import axios from "axios";
import { BASE_API_URL } from "../../../../constant/url";
const { confirm } = Modal;

function HistoryBarangMasuk() {
  const [loading, setLoading] = useState(true);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [getAllHistoryBarangMasuk, setGetAllHistoryBarangMasuk] = useState([]);
  const [startIndex, setStartIndex] = useState(1);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [getAllListBarangRegister, setGetAllListBarangRegister] = useState([]);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [getAllCategory, setGetAllCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [searchTerm2, setSearchTerm2] = useState([]);
  const params = useParams();
  const { id } = params;

  const formatRupiah = (value) => {
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "Tanggal",
      dataIndex: "date",
      key: "date",
      render: (startDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(startDate).toLocaleDateString('id-ID', options);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Nama Barang/Spare Part",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    {
      title: "NO P.O",
      dataIndex: "ponumber",
      key: "ponumber",
    },
    {
      title: "Jumlah",
      dataIndex: "amount",
      key: "amount",
      align: 'right',
      render: (amount) => {
        const formattedTotal = parseFloat(amount).toLocaleString();
        return <span>{formattedTotal}</span>;
      },
    },
    {
      title: "Satuan",
      dataIndex: "unit",
      key: "unit",
      align: 'right',
    },
    {
      title: "Harga Satuan",
      dataIndex: "unitprice",
      key: "unitprice",
      render: (_, record) => `Rp. ${formatRupiah(record.unitprice)}`,
      align: 'right',
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
  ];

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/registerItem/list-itemsbyid?inventories_id=${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListBarangRegister(response.data.data);
        setSearchTerm(response.data.data);
        console.log("history", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List Inventories
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List kategori
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepart/list-sparepart-category`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllCategory(response.data.data);
        setSearchTerm2(response.data.data);
        console.log("catgori", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const listStatus = getAllCategory.map((category) => ({
    label: category.itemcategory,
    value: category.id,
  }));

  const mergedData = getAllListBarangRegister.map((registerItem) => {
    const inventoryItem = getAllInventory.find(
      (inventory) =>
        String(inventory.id) === String(registerItem.inventories_id)
    );

    return {
      ...registerItem,
      inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
    };
  });

  mergedData.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={styles.main}>
          <div>
            <Table
              className={styles.tableCustomers}
              bordered
              dataSource={mergedData}
              columns={columns}
              pagination={{
                pageSize: 10,
                onChange: (page, pageSize) => {
                  setStartIndex((page - 1) * pageSize + 1);
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default HistoryBarangMasuk;
