import React, { useEffect, useState } from "react";
import styles from "./EditBarang.module.css";
import {
  message,
  DatePicker,
  Table,
  Input,
  Dropdown,
  Select,
  Modal,
  Alert,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { BASE_API_URL } from "../../../../constant/url";
// import { Table } from "react-bootstrap";
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};

function EditBarang() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [getAllLeave, setGetAllLeave] = useState([]);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [selectedBarang, setSelectedBarang] = useState(null);
  const [admin, setadmin] = useState({
    date: "",
    inventories_id: "",
    ponumber: "",
    amount: "",
    unit: "",
    unitprice: "",
    vendor: "",
    user: "",
  });
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setadmin({
      ...admin,
      [event.target.name]: event.target.value,
    });
  };

  const handleBarangChange = (value) => {
    setSelectedBarang(value);
    setadmin({
      ...admin,
      inventories_id: value,
    });
  };

  //handle input
  const handleInsertNewItems = async () => {
    setLoading(true);

    try {
      const response = await axios.put(
        `${BASE_API_URL}/v1/registerItem/register-item/${id}`,
        admin,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Items added successfully");
      setLoading(false);
      setTimeout(() => {
        navigate("/barangMasuk");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        logout();
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.data.statusCode === 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/registerItem/detail-item/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setadmin(response.data.data);
        // console.log("inventoriesedit", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const inventoryItem = getAllInventory.find(
    (inventory) => String(inventory.id) === String(admin.inventories_id)
  );
  const inventoryNameToShow = inventoryItem ? inventoryItem.inventoryname : "";

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Edit Data</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Tanggal
              </label>
              <Input
                required="true"
                type="date"
                name="date"
                value={admin.date}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Nama Barang Keluar
              </label>
              <Select
                className={styles.formControl}
                showSearch
                placeholder="Pilih barang"
                optionFilterProp="children"
                onChange={handleBarangChange}
                value={inventoryNameToShow}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={getAllInventory.map((item) => ({
                  value: item.id,
                  label: item.inventoryname,
                }))}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                NO P.O
              </label>
              <Input
                required="true"
                type="text"
                name="ponumber"
                value={admin.ponumber}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Type"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Jumlah
              </label>
              <Input
                required={true}
                type="text"
                name="amount"
                // value={admin.amount}
                // value={parseFloat(admin.amount).toFixed(3)}
                value={admin.amount}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Jumlah Barang"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Satuan
              </label>
              <Input
                required="true"
                type="text"
                name="unit"
                value={admin.unit}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Destination Item"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Harga Satuan
              </label>
              <Input
                required="true"
                type="number"
                name="unitprice"
                value={admin.unitprice}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Destination Item"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Vendor
              </label>
              <Input
                required="true"
                type="text"
                name="vendor"
                value={admin.vendor}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Destination Item"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                User
              </label>
              <Input
                required="true"
                type="text"
                name="user"
                value={admin.user}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Destination Item"
              />
            </div>

            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewItems}
                >
                  Edit Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EditBarang;
