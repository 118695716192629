import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./DetailPersediaan.module.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
  Tag,
  Tabs,
} from "antd";
import axios from "axios";
import { BASE_API_URL } from "../../../../constant/url";
import HistoryBarangKeluar from "../../History/HistoryBarangKeluar/HistoryBarangKeluar";
import HistoryBarangMasuk from "../../History/HistoryBarangMasuk/HistoryBarangMasuk";
const { TabPane } = Tabs;

function DetailPersediaan() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const params = useParams();
  const { id } = params;
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") || "barangmasuk" || "barangkeluar"
  );

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };

  const { Column, ColumnGroup } = Table;

  useEffect(() => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/detailinventory/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
            message.error("Your session has expired. Please login again.");
          } else if (error.response.status == 404) {
            // Handle 404 error
          } else if (error.response.status === 403) {
            message.error("Access denied");
          } else {
            message.error("Unknown Error");
          }
        } else {
          // Handle other types of errors here
          console.log(error);
        }
      });
  }, []);

  return (
    <DashboardLayout>
      <h3 className={styles.titleWrapper}>Detail Persediaan</h3>
      <div className={styles.mainDetail}>
        <div className={styles.mainUserDetail}>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>PT. KALTIM INDUSTRIAL ESTATE</p>
            </div>
          </div>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>Nama Persediaan </p>
            </div>
            <div className={styles.mainRightData}>
              <p className={styles.textDetail}>: {getAllSpending.inventoryname} </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="History Barang Masuk" key="barangmasuk">
            <HistoryBarangMasuk />
          </TabPane>
          <TabPane tab="History Barang Keluar" key="barangkeluar">
            <HistoryBarangKeluar />
          </TabPane>

        </Tabs>
      </div>
    </DashboardLayout>
  );
}

export default DetailPersediaan;
