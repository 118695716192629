import React, { useState } from "react";
import styles from "./ListPemasukanBahanBaku.module.css";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../../constant/url";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { useEffect } from "react";
import Search from "antd/es/input/Search";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;

function ListPemasukanBahanBaku() {
  const [loading, setLoading] = useState(false);
  const [getAllLeave, setGetAllLeave] = useState([]);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [getAllListBarangRegister, setGetAllListBarangRegister] = useState([]);
  const [getAllListCommitment, setGetAllListCommitment] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  const formatRupiah = (value) => {
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },

    {
      title: "Nama Bahan Baku",
      dataIndex: "commitment_raw_material_name",
      key: "commitment_raw_material_name",
    },
    {
      title: "Tanggal",
      dataIndex: "raw_material_date",
      key: "raw_material_date",
      render: (startDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(startDate).toLocaleDateString('id-ID', options);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Jumlah Pemasukan Bahan Baku ",
      dataIndex: "raw_material_total",
      key: "raw_material_total",
      align: "right",
      render: (raw_material_total) => {
        const formattedTotal = parseFloat(raw_material_total).toLocaleString();
        return <span>{formattedTotal}</span>;
      },
    },
    {
      title: "Satuan Bahan Baku",
      dataIndex: "raw_material_unit",
      key: "raw_material_unit",
    },
    // {
    //   title: "Action",
    //   key: "aksi",
    //   width: "10%",
    //   render: (_, record) => {
    //     if (getAllListBarangRegister.length >= 1) {
    //       return (
    //         <div className={styles.btngroup}>
    //           <NavLink
    //             to={`/kategori-barang/edit-kategori/${record.id}`}
    //             className={styles.btnEditCustomer}
    //           >
    //             <AiFillEdit />
    //           </NavLink>
    //           {/* <button
    //             className={styles.btnDeleteAdmin}
    //             onClick={() => deleteItems(record.id)}
    //           >
    //             <AiFillDelete />
    //           </button> */}
    //         </div>
    //       );
    //     }
    //   },
    // },
  ];

  console.log("kolom", columns);

  // List Komitmen Out
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/raw-material/list-commitment-out`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListBarangRegister(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  // List Commitment
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/commitment-raw_material/list-commitment`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListCommitment(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);


  const deleteItems = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/sparepart/deletesparepart/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Items successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  const handleChangeSearch = (e) => {
    console.log(e);
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllListBarangRegister(searchTerm);
    } else {
      const filteredData = searchTerm.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText)
        )
      );
      setGetAllListBarangRegister(filteredData);
    }
  };

  const mergedData = getAllListBarangRegister.map((registerItem) => {
    const commitmentItem = getAllListCommitment.find(
      (commitment) =>
        String(commitment.id) === String(registerItem.commitment_raw_material_id)
    );

    return {
      ...registerItem,
      commitment_raw_material_name: commitmentItem ? commitmentItem.commitment_raw_material_name : null,
    };
  });
  mergedData.sort((a, b) => new Date(b.registersdate) - new Date(a.registersdate));

  console.log("mergedata", mergedData);



  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.wrapperMenu}>
            <div className={styles.wrapperButtonMenu}>
              <Search
                placeholder="Masukkan kata kunci pencarian"
                allowClear
                enterButton="Cari"
                size="large"
                onChange={handleChangeSearch}
                style={{ width: 400 }}
              />
              <div className={styles.buttonMenu1}>
                <NavLink
                  to="/pemasukan-bahan-baku/tambah-pemasukan-bahan-baku"
                  className={styles.btnAddJobs}
                >
                  Tambah Barang Baku
                </NavLink>
              </div>
            </div>
            <Table
              className={styles.tableCustomers}
              bordered
              dataSource={mergedData}
              columns={columns}
              pagination={{
                pageSize: 10,
                onChange: (page, pageSize) => {
                  setStartIndex((page - 1) * pageSize + 1);
                },
              }}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
export default ListPemasukanBahanBaku;
