import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./DashboardNav.module.css";
import { NavDropdown } from "react-bootstrap";
import { message, Skeleton } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { useEffect } from "react";

function DashboardNav() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("role");
  // const name = localStorage.getItem("name");
  const fullName = JSON.parse(localStorage.getItem("username"));

  const _onLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    // localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout successful");
    navigate("/");
  };

  return (
    // <div className={styles.wrapper}>
    //   {loading ? (
    //     <Skeleton />
    //   ) : (
    //     <div className={styles.container}>
    //       <div className={styles.left}>
    //         <h5>
    //           {role === "karyawan"
    //             ? "karyawan"
    //             : role === "petinggi"
    //             ? "petinggi"
    //             : "leader"}
    //         </h5>
    //       </div>
    //       <div className={styles.right}>
    //         <div className={styles.user}>
    //           <FaUserCircle className={styles.navbarAvatar} />
    //           <NavDropdown
    //             className={styles.dropdownContainer}
    //             title={
    //               <span className={styles.userName}>
    //                 {/* {fullName.data.user.name} */}
    //               </span>
    //             }
    //           >
    //             {/* <NavDropdown.Item>Profile</NavDropdown.Item>
    //             <NavDropdown.Divider /> */}
    //             <NavDropdown.Item onClick={_onLogout}>Log Out</NavDropdown.Item>
    //           </NavDropdown>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>
    <div className={styles.wrapper}>
      {loading ? (
        <Skeleton />
      ) : (
        <div className={styles.container}>
          <div className={styles.left}>
            <h5>
              {" "}
              {role === "user"
                ? "User"
                : role === "admin"
                ? "Admin"
                : "Super Admin"}
            </h5>
          </div>
          <div className={styles.right}>
            <div className={styles.user}>
              <FaUserCircle className={styles.navbarAvatar} />
              <NavDropdown
                className={styles.dropdownContainer}
                title={
                  <span className={styles.userName}>
                    {role === "user"
                      ? "User"
                      : role === "admin"
                      ? "Admin"
                      : "Super Admin"}
                  </span>
                }
              >
                <NavDropdown.Item onClick={_onLogout}>Keluar</NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardNav;
