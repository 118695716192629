import React, { useEffect, useState } from "react";
import styles from "./AddPengeluaranSemen.module.css";
import { Alert, Input, Select, message } from "antd";
import DashboardLayout from "../../../../../layouts/dashboard-layout/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_API_URL } from "../../../../../constant/url";

function AddPengeluaranSemen() {
  const acccesToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [selectedBarang, setSelectedBarang] = useState(null);
  const [spending, setSpending] = useState({
    nosemen: "",
    datesemen: "",
    inventories_id: "",
    jumlahsemen: "",
    satuansemen: "",
    locationsemen: "",
    diminta: "",
    disetujui: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setSpending({
      ...spending,
      [event.target.name]: event.target.value,
    });
  };

  const handleBarangChange = (value) => {
    setSelectedBarang(value);
    setSpending({
      ...spending,
      inventories_id: value,
    });
  };

  //handle input
  const handleInsertNewSpending = async () => {
    setLoading(true);

    try {
      // if (!spending.spendingname) {
      //   setOpenAlert(true);
      //   setMessageVal("Please input Inventory");
      //   setLoading(false);
      //   return;
      // }
      const response = await axios.post(
        `${BASE_API_URL}/v1/pengeluarasemen/pengeluaran-semen`,
        spending,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Spending added successfully");
      setLoading(false);
      setTimeout(() => {
        navigate("/pengeluaran");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  //list barang
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };
    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("inventory", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Add Data</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Nomor Pengeluaran
              </label>
              <Input
                required="true"
                type="text"
                name="nosemen"
                value={spending.nosemen}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Nomor Pengeluaran "
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Tanggal Pengeluaran
              </label>
              <Input
                required="true"
                type="date"
                name="datesemen"
                value={spending.datesemen}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Tanggal Pengeluaran "
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Nama Barang Keluar
              </label>
              <Select
                className={styles.formControl}
                showSearch
                placeholder="Pilih barang"
                optionFilterProp="children"
                onChange={handleBarangChange}
                value={selectedBarang}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={getAllInventory.map((item) => ({
                  value: item.id,
                  label: item.inventoryname,
                }))}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Jumlah Pengeluaran Barang
              </label>
              <Input
                required="true"
                type="number"
                name="jumlahsemen"
                value={spending.jumlahsemen}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jumlah Pengeluaran Barang"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Satuan/Volume
              </label>
              <Input
                required="true"
                type="text"
                name="satuansemen"
                value={spending.satuansemen}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jenis Satuan"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Lokasi
              </label>
              <Input
                required="true"
                type="text"
                name="locationsemen"
                value={spending.locationsemen}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Diminta"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Diminta
              </label>
              <Input
                required="true"
                type="text"
                name="diminta"
                value={spending.diminta}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan yang melakukan request"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Disetujui
              </label>
              <Input
                required="true"
                type="text"
                name="disetujui"
                value={spending.disetujui}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan yang menyetujui"
              />
            </div>
            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewSpending}
                >
                  Add Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddPengeluaranSemen;
