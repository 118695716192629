import React, { useState } from "react";
import styles from "./AddDetailPersediaan.module.css";
import { Alert, Input, message } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { BASE_API_URL } from "../../../../constant/url";
import axios from "axios";

function AddDetailPersediaanBarang() {
  const [loading, setLoading] = useState(false);
  const [inventory, setInventory] = useState({
    inventoryname: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setInventory({
      ...inventory,
      [event.target.name]: event.target.value,
    });
  };

  //handle input
  const handleInsertNewItems = async () => {
    setLoading(true);

    try {
      if (!inventory.inventoryname) {
        setOpenAlert(true);
        setMessageVal("Tolong Masukkan Nama Barang");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${BASE_API_URL}/v1/inventory/inventory`,
        inventory,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Items added successfully");
      setLoading(false);
      setTimeout(() => {
        navigate("/persediaan");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Tambah Data Pengeluaran</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Tanggal
              </label>
              <Input
                required="true"
                type="date"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Vendor/Lokasi
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>

            <h3 className={styles.addAdminTitle}>Barang Masuk</h3>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                NO PO
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Volume
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Harga
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>

            <h3 className={styles.addAdminTitle}>Barang Keluar</h3>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                NO MIR
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Volume
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Harga Satuan
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <h3 className={styles.addAdminTitle}>Saldo Akhir</h3>

            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Nilai
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Volume
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Harga Satuan
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Nilai
              </label>
              <Input
                required="true"
                type="text"
                name="inventoryname"
                value={inventory.inventoryname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Item Name"
              />
            </div>

            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewItems}
                >
                  Add Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddDetailPersediaanBarang;
