import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { Skeleton, Breadcrumb, Tabs } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import styles from "./MasterKategori.module.css";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import ListKategoriBarang from "../ListKategoriBarang/ListKategoriBarang";
import ListKategoriBarangProyek from "../ListKategoriProyek/ListKategoriBarangProyek";

const { TabPane } = Tabs;

function MasterKategori() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(
        new URLSearchParams(location.search).get("tab") || "gudang" || "ex-proyek"
    );

    const handleTabChange = (key) => {
        setActiveTab(key);
        navigate(`?tab=${key}`);
    };

    return (
        <DashboardLayout>
            {/* {loading ? (
        <Skeleton />
      ) : ( */}
            <div className={styles.wrapper}>
                <div className={styles.topWrapper}>
                    <h2 className={styles.pageTitle}>Kategori Barang</h2>
                    <Breadcrumb
                        className={styles.breadcumbs}
                        items={[
                            {
                                href: "/dashboard",
                                title: <HomeOutlined />,
                            },
                            {
                                href: "/kategori-barang",
                                title: <span>Kategori Barang</span>,
                            },
                        ]}
                    />
                </div>
                <div className={styles.main}>
                    {/* <div className={styles.pageInfo}>
                        <h3 className={styles.pageInfoTitle}>List Akun</h3>
                        <p className={styles.pageInfoDecsription}>
                            Daftar akun yang telah terdaftar di aplikasi
                        </p>
                    </div> */}

                    <div className={styles.content}>
                        <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="Kategori Barang Gudang" key="gudang">
                                <ListKategoriBarang />
                            </TabPane>
                            <TabPane tab="Kategori Barang Ex-Proyek" key="ex-proyek">
                                <ListKategoriBarangProyek />
                            </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
            {/* )} */}
        </DashboardLayout>
    );
}

export default MasterKategori;
