import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton, Breadcrumb, Tooltip, message } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import {
  AiOutlineExclamationCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import styles from "./Dashboard.module.css";
import { BASE_API_URL } from "../../constant/url";
import axios from "axios";
import DashboardLayout from "../../layouts/dashboard-layout/DashboardLayout";
import { Link } from "react-router-dom";
import { isDOMComponent } from "react-dom/test-utils";

function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);

  const [totalAdmin, setTotalAdmin] = useState(0);
  const role = localStorage.getItem("role");
  const [datajs, setDatajs] = useState(0);

  const date = new Date();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/dashboard/index`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    axios(config)
      .then(function (response) {
        setDatajs(response.data.data);
        console.log("data", datajs);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response.data === undefined) {
          message.error(
            `Error 500: Ada masalah pada server, "masalah tidak diketahui"`
          );
        } else if (error.response.status === 401) {
          logout();
          message.error("Sesi telah berakhir, silahkan login kembali!");
          navigate("/");
        } else if (error.response.status === 404) {
          setLoading(false);
        } else {
          message.error(
            `Error ${error.response.status}: Ada masalah pada server, "${error.response.data.message}"`,
            3
          );
        }
      });
  }, []);

  return (
    <DashboardLayout>
      {/* {loading || loading2 || loading3 ? (
        <Skeleton />
      ) : ( */}
      <div className={styles.wrapper}>
        <div className={styles.topWrapper}>
          <h2 className={styles.pageTitle}>Dashboard</h2>
          <Breadcrumb
            className={styles.breadcumbs}
            items={[
              {
                href: "",
                title: <HomeOutlined />,
              },
              {
                href: "/dashboard",
                title: <span>Dashboard</span>,
              },
            ]}
          />
        </div>
        <div className={styles.main}>
          {/* ---------------------------------------------- */}
          {/* TABLE RECENT JOB & CUSTOMER */}
          {/* Kriteria Kota */}
          <div className={styles.dashboardKeuanganContainer}>
            <>
              {role === "admin" && (
                <div className={styles.dashboardKeuanganBoxs}>
                  {/* card */}
                  <div className={styles.dashboardKeuanganCardGreen}>
                    <div className={styles.cardColor}></div>
                    <Link to="/kategori-barang" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Kategori
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Kategori barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.category}
                        </span>
                      </p>
                    </Link>
                  </div>
                  {/* ------ */}
                  <div className={styles.dashboardKeuanganCardRed}>
                    <div className={styles.cardColor}></div>
                    <Link to="/persediaan" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Persediaan
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Persediaan barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.inventory}
                        </span>
                      </p>
                    </Link>
                  </div>
                  {/* ------ */}
                  <div className={styles.dashboardKeuanganCardBlue}>
                    <div className={styles.cardColor}></div>
                    <Link to="/barangMasuk" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Barang Masuk
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Barang masuk yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.registerItem}
                        </span>
                      </p>
                    </Link>
                  </div>

                  {/* ------ */}
                  <div className={styles.dashboardKeuanganCardPurple}>
                    <div className={styles.cardColor}></div>
                    <Link to="/pengeluaran" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Pengeluaran Barang
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Pengeluaran barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.spending}
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              )}
              {/* card */}
              {role === "user" && (
                <div className={styles.dashboardKeuanganBoxs}>
                  <div className={styles.dashboardKeuanganCardRed}>
                    <div className={styles.cardColor}></div>
                    <Link to="/persediaan" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Persediaan
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Persediaan barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.inventory}
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              )}
              {/* card */}
              {role === "superadmin" && (
                <div className={styles.dashboardKeuanganBoxs}>
                  {/* card */}
                  <div className={styles.dashboardKeuanganCardYellow}>
                    <div className={styles.cardColor}></div>
                    <Link to="/register" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Jumlah Pengguna
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Total user yang terdaftar pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.user}
                        </span>
                      </p>
                    </Link>
                  </div>
                  <div className={styles.dashboardKeuanganCardGreen}>
                    <div className={styles.cardColor}></div>
                    <Link to="/kategori-barang" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Kategori
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Kategori barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.category}
                        </span>
                      </p>
                    </Link>
                  </div>
                  {/* ------ */}
                  <div className={styles.dashboardKeuanganCardRed}>
                    <div className={styles.cardColor}></div>
                    <Link to="/persediaan" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Persediaan
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Persediaan barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.inventory}
                        </span>
                      </p>
                    </Link>
                  </div>
                  {/* ------ */}
                  <div className={styles.dashboardKeuanganCardBlue}>
                    <div className={styles.cardColor}></div>
                    <Link to="/barangMasuk" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Barang Masuk
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Barang masuk yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.registerItem}
                        </span>
                      </p>
                    </Link>
                  </div>

                  {/* ------ */}
                  <div className={styles.dashboardKeuanganCardPurple}>
                    <div className={styles.cardColor}></div>
                    <Link to="/pengeluaran" className={styles.cardLink}>
                      <div className={styles.dashboardKeuanganCardTop}>
                        <h6 className={styles.dashboardKeuanganCardTopTitle}>
                          Pengeluaran Barang
                        </h6>
                        <Tooltip
                          placement="bottom"
                          title={`Pengeluaran barang yang terdapat pada aplikasi`}
                        >
                          <AiOutlineQuestionCircle className={styles.excIcon} />
                        </Tooltip>
                      </div>
                      <p className={styles.dashboardKeuanganCardValue}>
                        <span className={styles.dashboardKeuanganCardValueSpan}>
                          {datajs.spending}
                        </span>
                      </p>
                    </Link>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      {/* )} */}
    </DashboardLayout>
  );
}

export default Dashboard;
