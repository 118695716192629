import React, { useEffect, useState } from "react";
import styles from "./EditKategoriProyek.module.css";
import {
  message,
  DatePicker,
  Table,
  Input,
  Dropdown,
  Select,
  Modal,
  Alert,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { BASE_API_URL } from "../../../../constant/url";
// import { Table } from "react-bootstrap";
const { confirm } = Modal;

const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};

function EditKategoriProyek() {
  const [loading, setLoading] = useState(false);
  const [getAllLeave, setGetAllLeave] = useState([]);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [admin, setadmin] = useState({
    category_project: "",
  });
  const { id } = useParams();
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setadmin({
      ...admin,
      [event.target.name]: event.target.value,
    });
  };

  //handle input
  const handleInsertNewItems = async () => {
    setLoading(true);

    try {
      const response = await axios.put(
        `${BASE_API_URL}/v1/sparepartProject/sparepart-project/${id}`,
        admin,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Kategori Barang Berhasil Diubah");
      setLoading(false);
      setTimeout(() => {
        navigate("/kategori-barang?tab=ex-proyek");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepartProject/sparepart_project/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data);
        setadmin(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session is expired, please login again");
        }
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Edit Data</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="itemcategory" className={styles.formLabel}>
                Nama Kategori Barang
              </label>
              <Input
                required="true"
                type="text"
                name="category_project"
                value={admin.category_project}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Update kategori barang"
              />
            </div>

            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewItems}
                >
                  Edit Kategori Barang
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EditKategoriProyek;
