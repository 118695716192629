import React, { useEffect, useState } from "react";
import styles from "./AddBahanBaku.module.css";
import { Alert, Input, Select, message } from "antd";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_API_URL } from "../../../../constant/url";
function AddBahanBaku() {
  const acccesToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [selectedBarang, setSelectedBarang] = useState(null);
  const [spending, setSpending] = useState({
    commitment_raw_material_name: "",
    commitment_raw_material_total: "",
    commitment_raw_unit: "",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setSpending({
      ...spending,
      [event.target.name]: event.target.value,
    });
  };

  //handle input
  const handleInsertNewSpending = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/commitment-raw_material/commitment`,
        spending,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Berhasil Membuat Komitmen");
      setLoading(false);
      setTimeout(() => {
        navigate("/bahan-baku");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Add Data</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Nama Bahan Baku
              </label>
              <Input
                required="true"
                type="text"
                name="commitment_raw_material_name"
                value={spending.commitment_raw_material_name}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jenis Satuan Bahan Baku"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Jumlah Bahan Baku
              </label>
              <Input
                required="true"
                type="text"
                // step="0.01"
                name="commitment_raw_material_total"
                value={spending.commitment_raw_material_total}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jumlah Bahan Bakug"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Satuan Bahan Baku
              </label>
              <Input
                required="true"
                type="text"
                // step="0.01"
                name="commitment_raw_unit"
                value={spending.commitment_raw_unit}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Satuan Bahan Baku"
              />
            </div>
            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewSpending}
                >
                  Tambah Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddBahanBaku;
