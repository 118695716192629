import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";

import Register from "./pages/Register/Register";
import Barang from "./pages/Karyawan/BarangMasuk/Barang/Barang";
import Pengeluaran from "./pages/Karyawan/Pengeluaran/Pengeluaran";
import BarangMasuk from "./pages/Karyawan/BarangMasuk/BarangMasuk";
import Persediaan from "./pages/Karyawan/Persediaan/ListPersediaan/Persediaan";
import DetailPersediaan from "./pages/Karyawan/Persediaan/DetailPersediaan/DetailPersediaan";
import DetailPengeluaran from "./pages/Karyawan/Pengeluaran/DetailPengeluaran/DetailPengeluaran";
import AddMasterData from "./pages/Karyawan/KategoriBarang/AddMasterData/AddMasterData";
import AddPengeluaranBarang from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaran"
import AddPersediaanBarang from "./pages/Karyawan/Persediaan/Add Persediaan/AddPersediaan";
import AddAdmin from "./pages/Register/AddAdmin/AddAdmin";
import EditBarang from "./pages/Karyawan/BarangMasuk/EditBarang/EditBarang";
import AddDetailPersediaanBarang from "./pages/Karyawan/Persediaan/AddDetailPersediaan/AddDetailPersediaan";
import AddDetailPengeluaranBarang from "./pages/Karyawan/Pengeluaran/AddDetailPengeluaran/AddDetailPengeluaran";
import ListKategoriBarang from "./pages/Karyawan/KategoriBarang/ListKategoriBarang/ListKategoriBarang";
import PengeluaranPasir from "./pages/Karyawan/Pengeluaran/ListPengeluaran/Pengeluaran Pasir/PengeluaranPasir";
import AddPengeluaranPasir from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranPasir/AddPengeluaranPasir";
import AddPengeluaranBBM from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranBBM/AddPengeluaranBBM";
import AddPengeluaranWTP from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranWTP/AddPengeluaranWTP";
import AddPengeluaranIpal from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranIpal/AddPengeluaranIpal";
import AddPengeluaranSemen from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranSemen/AddPengeluaranSemen";
import AddPengeluaranSipil from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranSipil/AddPengeluaranSipil";
import AddPengeluaranBatu from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranBatu/AddPengeluaranBatu";
import AddPengeluaranElectrical from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranElectrical/AddPengeluaranElectrical";
import AddPengeluaranMechanical from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/AddPengeluaranMechanical/AddPengeluaranMechanical";
import DeliveryOrder from "./pages/DO/DeliveryOrder";
import PrintDO from "./pages/DO/PrintDO";
import EditKategori from "./pages/Karyawan/KategoriBarang/EditKategori/EditKategori";
import EditPersediaanBarang from "./pages/Karyawan/Persediaan/Edit Persediaan/EditPersediaan";
import EditPengeluaranBarang from "./pages/Karyawan/Pengeluaran/Add Pengeluaran/Edit Pengeluaran/EditPengeluaran";
import Opname from "./pages/Karyawan/Stock-Opname/Opname";
import AddStockOpname from "./pages/Karyawan/Stock-Opname/Add Stock-Opname/AddStockOpname";
import MasterPersediaan from "./pages/Karyawan/Persediaan/MasterPersediaan/MasterPersediaan";
import MasterKategori from "./pages/Karyawan/KategoriBarang/MasterKategoriBarang/MasterKategori";
import AddMasterDataProyek from "./pages/Karyawan/KategoriBarang/AddMasterDataProyek/AddMasterDataProyek";
import AddPersediaanProject from "./pages/Karyawan/Persediaan/Add Persediaan Ex-proyek/AddPersediaanProject";
import EditKategoriProyek from "./pages/Karyawan/KategoriBarang/EditKategoriProyek/EditKategoriProyek";
import EditPersediaanProyek from "./pages/Karyawan/Persediaan/Edit Persediaan Proyek/EditPersediaanProyek";
import AddBahanBaku from "./pages/Karyawan/BahanBaku/AddBahanBaku/AddBahanBaku";
import ListBahanBaku from "./pages/Karyawan/BahanBaku/ListBahanBaku/ListBahanBaku";
import ListPemasukanBahanBaku from "./pages/Karyawan/PemasukanBahanBaku/ListPemasukanBahanBaku/ListPemasukanBahanBaku";
import AddPemasukanBahanBaku from "./pages/Karyawan/PemasukanBahanBaku/AddPemasukanBahanBaku/AddPemasukanBahanBaku";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/register"
          element={
            <PrivateRoute>
              <Register />
            </PrivateRoute>
          }
        />
        <Route
          path="/register/add-admin"
          element={
            <PrivateRoute>
              <AddAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/bahan-baku"
          element={
            <PrivateRoute>
              <ListBahanBaku />
            </PrivateRoute>
          }
        />
        <Route
          path="/bahan-baku/tambah-bahan-baku"
          element={
            <PrivateRoute>
              <AddBahanBaku />
            </PrivateRoute>
          }
        />
        <Route
          path="/pemasukan-bahan-baku"
          element={
            <PrivateRoute>
              <ListPemasukanBahanBaku />
            </PrivateRoute>
          }
        />
        <Route
          path="/pemasukan-bahan-baku/tambah-pemasukan-bahan-baku"
          element={
            <PrivateRoute>
              <AddPemasukanBahanBaku />
            </PrivateRoute>
          }
        />
        <Route
          path="/kategori-barang"
          element={
            <PrivateRoute>
              <MasterKategori />
            </PrivateRoute>
          }
        />
        <Route
          path="/kategori-barang/edit-kategori/:id"
          element={
            <PrivateRoute>
              <EditKategori />
            </PrivateRoute>
          }
        />
        <Route
          path="/kategori-barang/edit-kategori-project/:id"
          element={
            <PrivateRoute>
              <EditKategoriProyek />
            </PrivateRoute>
          }
        />
        <Route
          path="/kategori-barang/tambah-kategori"
          element={
            <PrivateRoute>
              <AddMasterData />
            </PrivateRoute>
          }
        />

        <Route
          path="/kategori-barang/tambah-kategori-proyek"
          element={
            <PrivateRoute>
              <AddMasterDataProyek />
            </PrivateRoute>
          }
        />

        <Route
          path="/barangMasuk"
          element={
            <PrivateRoute>
              <BarangMasuk />
            </PrivateRoute>
          }
        />
        <Route
          path="/barangMasuk/edit-barang/:id"
          element={
            <PrivateRoute>
              <EditBarang />
            </PrivateRoute>
          }
        />
        <Route
          path="/barangMasuk/add-barang"
          element={
            <PrivateRoute>
              <Barang />
            </PrivateRoute>
          }
        />
        <Route
          path="/barangMasuk/add-master-data"
          element={
            <PrivateRoute>
              <AddMasterData />
            </PrivateRoute>
          }
        />

        <Route
          path="/persediaan"
          element={
            <PrivateRoute>
              <MasterPersediaan />
            </PrivateRoute>
          }
        />

        <Route
          path="/opname"
          element={
            <PrivateRoute>
              <Opname />
            </PrivateRoute>
          }
        />
        <Route
          path="/opname/tambah-stock-opaname"
          element={
            <PrivateRoute>
              <AddStockOpname />
            </PrivateRoute>
          }
        />

        <Route
          path="/persediaan/edit-persediaan/:id"
          element={
            <PrivateRoute>
              <EditPersediaanBarang />
            </PrivateRoute>
          }
        />

        <Route
          path="/persediaan/edit-persediaan-proyek/:id"
          element={
            <PrivateRoute>
              <EditPersediaanProyek />
            </PrivateRoute>
          }
        />
        <Route
          path="/persediaan/detail-persediaan/:id"
          element={
            <PrivateRoute>
              <DetailPersediaan />
            </PrivateRoute>
          }
        />
        <Route
          path="/persediaan/detail-persediaan/add-detail-persediaan"
          element={
            <PrivateRoute>
              <AddDetailPersediaanBarang />
            </PrivateRoute>
          }
        />
        <Route
          path="/persediaan/add-persediaan"
          element={
            <PrivateRoute>
              <AddPersediaanBarang />
            </PrivateRoute>
          }
        />
        <Route
          path="/persediaan/tambah-persediaan-project"
          element={
            <PrivateRoute>
              <AddPersediaanProject />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran"
          element={
            <PrivateRoute>
              <Pengeluaran />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/edit-pengeluaran/:id"
          element={
            <PrivateRoute>
              <EditPengeluaranBarang />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaranpasir"
          element={
            <PrivateRoute>
              <PengeluaranPasir />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-pasir"
          element={
            <PrivateRoute>
              <AddPengeluaranPasir />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-bbm"
          element={
            <PrivateRoute>
              <AddPengeluaranBBM />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-wtp"
          element={
            <PrivateRoute>
              <AddPengeluaranWTP />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-ipal"
          element={
            <PrivateRoute>
              <AddPengeluaranIpal />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-semen"
          element={
            <PrivateRoute>
              <AddPengeluaranSemen />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/add-pengeluaran-sipil"
          element={
            <PrivateRoute>
              <AddPengeluaranSipil />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-batu"
          element={
            <PrivateRoute>
              <AddPengeluaranBatu />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/add-pengeluaran-electrical"
          element={
            <PrivateRoute>
              <AddPengeluaranElectrical />
            </PrivateRoute>
          }
        />

        <Route
          path="/pengeluaran/add-pengeluaran-mechanical"
          element={
            <PrivateRoute>
              <AddPengeluaranMechanical />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/add-pengeluaran"
          element={
            <PrivateRoute>
              <AddPengeluaranBarang />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/detail-pengeluaran/:id"
          element={
            <PrivateRoute>
              <DetailPengeluaran />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/detail-pengeluaran/add-detail-pengeluaran"
          element={
            <PrivateRoute>
              <AddDetailPengeluaranBarang />
            </PrivateRoute>
          }
        />
        <Route
          path="/pengeluaran/detail-pengeluaran/:id/updateDO"
          element={
            <PrivateRoute>
              <DeliveryOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/printdo"
          element={
            <PrivateRoute>
              <PrintDO />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

function PrivateRoute({ children }) {
  const isLoggedIn = localStorage.getItem("accessToken");
  return isLoggedIn ? children : <Navigate to="/" replace={true} />;
}

function PublicRoute({ children }) {
  const isLoggedIn = localStorage.getItem("accessToken");
  return isLoggedIn ? <Navigate to="/dashboard" replace={true} /> : children;
}

export default App;
