import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./styles/PreviewDO.module.css";

import { Input, Modal, Select, Skeleton, Tabs, message } from "antd";
import {
  createDeliveryOrder,
  fetchDeliveryOrder,
  fetchDeliveryOrderById,
} from "../../services/DO";
import { getCurrentDate } from "../../helper/formatter";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAPIError } from "../../helper/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { updateJobStatus } from "../../services/Jobs";
import { BASE_API_URL } from "../../constant/url";
import axios from "axios";
import { KieLogo } from "../../assets";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import PrintDO from "./PrintDO";
const { confirm } = Modal;

function PreviewDO({ listAttn, jobStatus, company, jobNumber }) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") || "do"
  );
  const [inventories, setInventories] = useState([]);
  const params = useParams();
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [loadingGenerateDO, setLoadingGenerateDO] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [messageVal, setMessageVal] = useState("");
  const [showPrintComponent, setShowPrintComponent] = useState(false);
  const [deliveryorder, setDeliveryOrder] = useState({
    jeniskendaraan: "",
    nomorkendaraan: "",
    nama_penerima: "",
  });

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const handleChange = (event) => {
    setDeliveryOrder({
      ...deliveryorder,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/spendings/detail-spending/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
            message.error("Your session has expired. Please login again.");
          } else if (error.response.status == 404) {
            // Handle 404 error
          } else if (error.response.status === 403) {
            message.error("Access denied");
          } else {
            message.error("Unknown Error");
          }
        } else {
          // Handle other types of errors here
          console.log(error);
        }
      });
  }, []);

  //handle change
  // const handleChange = (event) => {
  //   const Jobnumber = event.target.value.toUpperCase();
  //   setadmin({
  //     ...admin,
  //     job_no: Jobnumber,
  //   });
  // };

  //handle input
  const handleInsertDeliveryOrder = async () => {
    setLoading(true);

    try {
      const payload = {
        jeniskendaraan: deliveryorder.jeniskendaraan,
        nomorkendaraan: deliveryorder.nomorkendaraan,
      };

      const response = await axios.put(
        `${BASE_API_URL}/v1/spendings/spendings/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Delivery Order Berhasil Dibuat");
      setLoading(false);
      // setTimeout(() => {
      //   navigate(`/pengeluaran/detail-pengeluaran/{id}`);
      // }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const inventoryItem = getAllInventory.find(
    (inventory) =>
      String(inventory.id) === String(getAllSpending.inventories_id)
  );
  const inventoryNameToShow = inventoryItem ? inventoryItem.inventoryname : "";

  // const handlePrint = () => {
  //   const printWindow = window.open("", "_blank");
  //   printWindow.document.write("<html><head><title>Cetak</title>");

  //   const containerWrapper = document.querySelector(
  //     `.${styles.containerWrapper}`
  //   );
  //   printWindow.document.write(containerWrapper.outerHTML);

  //   // Selesaikan pembuatan halaman cetakan
  //   printWindow.document.write("</body></html>");
  //   printWindow.document.close();

  //   // Cetak halaman
  //   printWindow.print();

  //   // Tutup jendela cetakan setelah pencetakan selesai
  //   printWindow.onafterprint = () => {
  //     printWindow.close();
  //   };
  // };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Cetak</title>");

    const containerWrapper = document.querySelector(
      `.${styles.containerWrapper}`
    );
    const containerHTML = containerWrapper.outerHTML;

    // Gunakan media query untuk mengatur tampilan cetak
    const printStyles = `
      <style>
        @media print {
          body {
            margin: 0;
          }
          .no-print {
            display: none;
          }
          @page {
            size: landscape;
            margin: 0;
          }
        }
      </style>
    `;

    printWindow.document.write(printStyles);
    printWindow.document.write(containerHTML);

    // Selesaikan pembuatan halaman cetakan
    printWindow.document.write("</body></html>");
    printWindow.document.close();

    // Cetak halaman
    printWindow.print();

    // Tutup jendela cetakan setelah pencetakan selesai
    printWindow.onafterprint = () => {
      printWindow.close();
    };
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableMenu}>
        <div className={styles.wrapperButton}>
          <NavLink
            to={`/pengeluaran/detail-pengeluaran/${id}/updateDO`}
            className={styles.btnAddJobs}
          >
            Update Data
          </NavLink>
        </div>
        <div className={styles.wrapperButton1}>
          <button onClick={handlePrint} className={styles.btnAddJobs}>
            Cetak
          </button>
        </div>
      </div>
      <>
        <div className={styles.menu}>
          <h3 className={styles.titleWrapper}>Preview Delivery Order</h3>
          <div
            className={styles.containerWrapper}
            style={{ border: "1px solid black" }}
          >
            <div
              className={styles.wrapperHeader}
              style={{
                display: "flex",
                flexDirection: "column",
                borderBottom: "1px solid black",
                padding: "10px 10px",
                alignItems: "center",
              }}
            >
              <img
                className={styles.imageLogo}
                style={{ width: "70px", height: "50px", alignItems: "center" }}
                src={KieLogo}
                alt=""
              />

              <h3
                className={styles.wrapperHeaderTitle}
                style={{
                  alignItems: "center",
                  marginLeft: "20px",
                  textAlign: "center",
                }}
              >
                Kaltim Industrial Estate
              </h3>
            </div>
            <div
              className={styles.wrapperBody1}
              style={{
                //  marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <div
                className={styles.titlebody}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  // marginTop: "10px",
                }}
              >
                <h4>Bukti Penyerahan Material</h4>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div className={styles.mainLeftData} style={{ flex: 1 }}>
                  <p className={styles.titleDetail}>Nomor</p>
                </div>
                <div className={styles.mainRightData} style={{ flex: 3 }}>
                  <p className={styles.textDetail}>
                    : {getAllSpending.nospendings}
                  </p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Dari</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : PT. Kaltim Industrial Estate
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.wrapperBody2}>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Hari/tanggal</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    :{" "}
                    {new Date(
                      getAllSpending.spendingsdate
                    ).toLocaleDateString()}
                  </p>
                </div>
              </div>
              {/* <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Diminta Oleh</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {getAllSpending.diminta}
                  </p>
                </div>
              </div> */}
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Kepada</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {getAllSpending.acceptspendings}
                  </p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Jenis Material</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>: {inventoryNameToShow}</p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Jumlah</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {(() => {
                      const formattedTotal = parseFloat(getAllSpending.totalspendings).toLocaleString();
                      return <span>{formattedTotal}</span>;
                    })()}{" "}
                    {getAllSpending.unitspendings}
                  </p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Jenis Kendaraan</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {getAllSpending.jeniskendaraan}
                  </p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Transport</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {getAllSpending.nomorkendaraan}
                  </p>
                </div>
              </div>
              <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Lokasi Penerimaan</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {getAllSpending.locationspendings}
                  </p>
                </div>
              </div>
              {/* <div
                className={styles.mainDetailData}
                style={{ display: "flex", marginLeft: "20px" }}
              >
                <div
                  className={styles.mainLeftData}
                  style={{ flex: 1, margin: 0 }}
                >
                  <p className={styles.titleDetail}>Nama Penerima</p>
                </div>
                <div
                  className={styles.mainRightData}
                  style={{ flex: 3, margin: 0 }}
                >
                  <p className={styles.textDetail}>
                    : {getAllSpending.nama_penerima}
                  </p>
                </div>
              </div> */}
            </div>
            <div
              className={styles.wrapperFooter}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                borderTop: "1px solid black",
              }}
            >
              <div>
                <span>Diterima Oleh</span>
              </div>
              <div>
                <span>Diserahkan Oleh</span>
              </div>
            </div>
            <div
              className={styles.wrapperFooter2}
              style={{
                display: "flex",
                marginTop: "80px",
                flexDirection: "row",
                justifyContent: "space-around",
                marginBottom: "20px",
              }}
            >
              <div>
                <span>(........................................)</span>
              </div>
              <div>
                <span>(........................................)</span>
              </div>
            </div>
            {/* <div className={styles.noteDokumen}>
              <span>
                *Dokumen ini telah disetujui oleh pejabat terkait dan
                terverifikasi melalui WAMENT
              </span>
            </div> */}
          </div>
        </div>
      </>
    </div>
  );
}

export default PreviewDO;
