import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles/DeliveryOrder.module.css";

import { Input, Modal, Select, Skeleton, Tabs, message } from "antd";
import {
  createDeliveryOrder,
  fetchDeliveryOrder,
  fetchDeliveryOrderById,
} from "../../services/DO";
import { getCurrentDate } from "../../helper/formatter";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { handleAPIError } from "../../helper/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { updateJobStatus } from "../../services/Jobs";
import { BASE_API_URL } from "../../constant/url";
import axios from "axios";
import DashboardLayout from "../../layouts/dashboard-layout/DashboardLayout";

const { confirm } = Modal;

function DeliveryOrder() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState({
    jeniskendaraan: "",
    nomorkendaraan: "",
    nana_penerima: "",
    // tanggal_keluar: "",
  });
  const [getAllInventory, setGetAllInventory] = useState([]);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") || "do"
  );
  const [inventories, setInventories] = useState([]);
  const params = useParams();
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [loadingGenerateDO, setLoadingGenerateDO] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");

  const [deliveryorder, setDeliveryOrder] = useState({
    jeniskendaraan: "",
    nomorkendaraan: "",
    nama_penerima: "",
    diminta: "",
  });
  const [spending, setSpending] = useState({
    inventories_id: "",
    nospendings: "",
    descriptionprodukspendings: "",
    // periodespendings: "",
    locationspendings: "",
    unitspendings: "",
    totalspendings: "",
    requestspendings: "",
    acceptspendings: "",
    spendingsdate: "",
    jeniskendaraan: "",
    nomorkendaraan: "",
    nama_penerima: "",
    diminta: ""
  });
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const handleChange = (event) => {
    setSpending({
      ...spending,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/spendings/detail-spending/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setSpending(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
            message.error("Your session has expired. Please login again.");
          } else if (error.response.status == 404) {
            // Handle 404 error
          } else if (error.response.status === 403) {
            message.error("Access denied");
          } else {
            message.error("Unknown Error");
          }
        } else {
          // Handle other types of errors here
          console.log(error);
        }
      });
  }, []);

  //handle input
  const handleInsertDeliveryOrder = async () => {
    setLoading(true);

    try {
      const payload = {
        jeniskendaraan: spending.jeniskendaraan,
        nomorkendaraan: spending.nomorkendaraan,
        nama_penerima: spending.nama_penerima,
        diminta: spending.diminta,
      };

      const response = await axios.put(
        `${BASE_API_URL}/v1/spendings/spendings/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Delivery Order Berhasil Dibuat");
      setLoading(false);
      // setTimeout(() => {
      //   navigate(`/pengeluaran/detail-pengeluaran/{id}`);
      // }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const inventoryItem = getAllInventory.find(
    (inventory) =>
      String(inventory.id) === String(getAllSpending.inventories_id)
  );
  const inventoryNameToShow = inventoryItem ? inventoryItem.inventoryname : "";

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <>
          <div className={styles.menu}>
            <h3 className={styles.titleWrapper}>Delivery Order</h3>

            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnDownload}>Loading...</button>
              ) : (
                <button
                  className={styles.btnDownload}
                  onClick={handleInsertDeliveryOrder}
                >
                  Update Delivery Order
                </button>
              )}
            </div>
            <div className={styles.form}>
              {/* <div className={styles.formGroup}>
                <label htmlFor="attnC" className={styles.formLabel}>
                  Nama Penerima
                </label>
                <Input
                  required="true"
                  type="text"
                  name="nama_penerima"
                  value={spending.nama_penerima}
                  onChange={handleChange}
                  className={styles.formControl}
                  placeholder="Masukkan Nomor Pengeluaran "
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="attnC" className={styles.formLabel}>
                  Diminta Oleh
                </label>
                <Input
                  required="true"
                  type="text"
                  name="diminta"
                  value={spending.diminta}
                  onChange={handleChange}
                  className={styles.formControl}
                  placeholder="Masukkan Nomor Pengeluaran "
                />
              </div> */}
              <div className={styles.formGroup}>
                <label htmlFor="attnC" className={styles.formLabel}>
                  Jenis Kendaraan
                </label>
                <Input
                  required="true"
                  type="text"
                  name="jeniskendaraan"
                  value={spending.jeniskendaraan}
                  onChange={handleChange}
                  className={styles.formControl}
                  placeholder="Masukkan Nomor Pengeluaran "
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="attnC" className={styles.formLabel}>
                  Nomor Kendaraan
                </label>
                <Input
                  required="true"
                  type="text"
                  name="nomorkendaraan"
                  value={spending.nomorkendaraan}
                  onChange={handleChange}
                  className={styles.formControl}
                  placeholder="Masukkan Nomor Pengeluaran "
                />
              </div>
              {/* <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Diminta Oleh
              </label>
              <Input
                required="true"
                type="text"
                // name="nobatu"
                value={getAllSpending.requestspendings}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Nomor Pengeluaran "
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Disetujui Oleh
              </label>
              <Input
                required="true"
                type="text"
                // name="nobatu"
                value={getAllSpending.acceptspendings}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Nomor Pengeluaran "
              />
            </div> */}
            </div>
          </div>
        </>
      </div>
    </DashboardLayout>
  );
}

export default DeliveryOrder;
