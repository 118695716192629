import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./AddAdmin.module.css";
import { Input, Breadcrumb, message, Alert, Select } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../constant/url";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

function AddAdmin(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [admin, setadmin] = useState({
    fullname: "",
    email: "",
    NPK: "",
    password: "",
    role: "",
  });
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  };

  const [selectedRole, setSelectedRole] = useState(false);

  const handleRoleChange = (value) => {
    setadmin({
      ...admin,
      role: value,
    });
    setSelectedRole(value === "admin");
  };

  //handle change
  const handleChange = (event) => {
    setadmin({
      ...admin,
      [event.target.name]: event.target.value,
    });
  };

  //handle input
  const handleInsertNewAdmin = async () => {
    setLoading(true);

    try {
      if (!admin.fullname) {
        setOpenAlert(true);
        setMessageVal("Please input Admin Name");
        setLoading(false);
        return;
      } else if (!admin.email) {
        setOpenAlert(true);
        setMessageVal("Please input Admin Email");
        setLoading(false);
        return;
      } else if (!validateEmail(admin.email)) {
        setOpenAlert(true);
        setMessageVal("Email not valid");
        setLoading(false);
        return;
      } else if (!admin.password) {
        setOpenAlert(true);
        setMessageVal("Please input password");
        setLoading(false);
        return;
      } else if (admin.password.length < 6) {
        setOpenAlert(true);
        setMessageVal("Password cannot be less than 6 characters");
        setLoading(false);
        return;
      } else if (!admin.role) {
        setOpenAlert(true);
        setMessageVal("Please select Admin Role");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${BASE_API_URL}/v1/auth/register`,
        admin
      );
      message.success("Admin added successfully");
      setLoading(false);
      setTimeout(() => {
        navigate("/register");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.topWrapper}>
          <h2 className={styles.pageTitle}>Tambah Akun</h2>
        </div>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Tambah Akun Baru</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Name
              </label>
              <Input
                required="true"
                type="text"
                name="fullname"
                value={admin.fullname}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Admin Name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnA" className={styles.formLabel}>
                Email
              </label>
              <Input
                required
                type="text"
                name="email"
                value={admin.email}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Admin Email"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnA" className={styles.formLabel}>
                NPK
              </label>
              <Input
                required
                type="text"
                name="NPK"
                value={admin.NPK}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input NPK"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnB" className={styles.formLabel}>
                Password
              </label>
              <Input.Password
                required
                type="text"
                name="password"
                value={admin.password}
                maxLength="10"
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Input Password (Maximum 8 Characters)"
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Role
              </label>

              <Select
                className={styles.formControl}
                showSearch
                placeholder="Choose Role"
                optionFilterProp="children"
                onChange={handleRoleChange}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "user",
                    label: "User",
                  },
                  {
                    value: "admin",
                    label: "Admin",
                  },
                  {
                    value: "superadmin",
                    label: "Super Admin",
                  },
                ]}
              />
            </div>

            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewAdmin}
                >
                  Tambah Akun
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddAdmin;
