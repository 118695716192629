import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { Skeleton, Breadcrumb, Tabs } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import styles from "./Register.module.css";
import DashboardLayout from "../../layouts/dashboard-layout/DashboardLayout";
import ListUser from "./ListUser/ListUser";
import ListAdmin from "./ListAdmin/ListAdmin";
import ListSuperAdmin from "./ListSuperAdmin/ListSuperAdmin";

const { TabPane } = Tabs;

function Register() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") || "user" || "admin"
  );

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };

  return (
    <DashboardLayout>
      {/* {loading ? (
        <Skeleton />
      ) : ( */}
      <div className={styles.wrapper}>
        <div className={styles.topWrapper}>
          <h2 className={styles.pageTitle}>Akun</h2>
        </div>
        <div className={styles.main}>
          <div className={styles.pageInfo}>
            <h3 className={styles.pageInfoTitle}>List Akun</h3>
            <p className={styles.pageInfoDecsription}>
              Daftar akun yang telah terdaftar di aplikasi
            </p>
          </div>
          <div className={styles.buttonCreateData}>
            <NavLink to="/register/add-admin" className={styles.btnAddAdmin}>
              Tambah Akun
            </NavLink>
          </div>
          <div className={styles.content}>
            <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
              <TabPane tab="List User" key="user">
                <ListUser />
              </TabPane>
              <TabPane tab="List Admin" key="admin">
                <ListAdmin />
              </TabPane>
              <TabPane tab="List Super Admin" key="superadmin">
                <ListSuperAdmin />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      {/* )} */}
    </DashboardLayout>
  );
}

export default Register;
