import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./Opname.module.css";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../constant/url";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { useEffect } from "react";
import Search from "antd/es/input/Search";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;

function Opname() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [getAllOpname, setGetAllOpname] = useState([]);
  const [getAllListKategoriBarang, setGetAllListKategoriBarang] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const [statusSelected, setStatusSelected] = useState("all");
  const [dataCommitment, setDataCommitment] = useState([]);
  const [ItemCategorySelected, setItemCategorySelected] = useState("all");
  const [searchTerm2, setSearchTerm2] = useState([]);
  const [costCenterSelected, setCostCenterSelecte] = useState([]);

  const columns = [
    {
      title: "No",
      width: "10%",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "Nama Persediaan",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    {
      title: "Kategori",
      dataIndex: "itemcategory",
      key: "itemcategory",
    },
    {
      title: "Jumlah Persediaan",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (total) => {
        const formattedTotal = parseFloat(total).toLocaleString();
        return <span>{formattedTotal}</span>;
      },
    },

    // {
    //   title: "Jumlah Persediaan",
    //   dataIndex: "total",
    //   key: "total",
    //   render: (total) => {
    //     console.log("Original Total:", total);

    //     const formattedTotal =
    //       total && !isNaN(parseFloat(total.replace(",", ".")))
    //         ? parseFloat(total.replace(",", ".")).toLocaleString()
    //         : 0;

    //     console.log("Formatted Total:", formattedTotal);

    //     return <span>{formattedTotal}</span>;
    //   },
    // },

    {
      title: "Satuan",
      dataIndex: "unit",
      key: "unit",
    },

    {

      title: "Action",
      width: "10%",
      key: "action",
      render: (_, record) => {
        if (role === "admin" || role === "superadmin") {
          return (
            <div className={styles.wrapperIcon}>
              <NavLink
                to={`/persediaan/edit-persediaan/${record.id}`}
                className={styles.btnEditCustomer}
              >
                <AiFillEdit />
              </NavLink>
            </div>
          );
        } else {
          return <span>-</span>;
        }
      },
    },
  ];

  //list inventories
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List Opname 
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/opname/list-opname`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllOpname(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);


  //List kategori
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepart/list-sparepart-category`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListKategoriBarang(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const listStatus = getAllListKategoriBarang.map((category) => ({
    label: category.itemcategory,
    value: category.id,
  }));

  const deleteInventory = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/inventory/deleteinventory/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Inventory successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  //excel

  const handledownload = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/inventory/inventory-excel`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob",
        }
      );

      // Create a Blob from the binary data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Persediaan.xlsx";
      document.body.appendChild(link);

      link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        logout();
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.data.statusCode === 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  const mergedData = getAllOpname.map((inventoryItem) => {
    const kategoriBarang = getAllListKategoriBarang.find(
      (inventory) =>
        String(inventory.id) === String(inventoryItem.categories_id)
    );
    const inventoryname = getAllInventory.find(
      (inventoryname) =>
        String(inventoryname.id) === String(inventoryItem.inventories_id)
    )

    return {
      ...inventoryItem,
      itemcategory: kategoriBarang ? kategoriBarang.itemcategory : null,
      inventoryname: inventoryname ? inventoryname.inventoryname : null
    };
  });

  // const handleChangeSearch = (value) => {
  //   setSearchTerm(value);
  //   const filteredInventory = getAllInventory.filter((item) =>
  //     item.inventoryname.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setGetAllInventory(filteredInventory);
  // };

  // const handleChangeSearch = (value) => {
  //   setSearchTerm(value);

  //   if (value === "") {
  //     // Kembalikan ke daftar lengkap
  //     setGetAllInventory([...getAllInventory]);
  //   } else {
  //     const filteredInventory = getAllInventory.filter((item) =>
  //       item.inventoryname.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setGetAllInventory([...filteredInventory]);
  //   }
  // };

  const handleChangeSearch = (e) => {
    console.log(e);
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllInventory(searchTerm);
    } else {
      const filteredData = searchTerm.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText)
        )
      );
      setGetAllInventory(filteredData);
    }
  };

  // const handleChangeSearch = (e) => {
  //   const searchText = e.target.value.toLowerCase();

  //   if (searchText === "") {
  //     // If searchText is empty, show the entire inventory list
  //     setGetAllInventory(searchTerm);
  //   } else {
  //     // If searchText is not empty, filter the inventory based on the search text
  //     const filteredData = searchTerm.filter((item) =>
  //       Object.values(item).some(
  //         (value) =>
  //           typeof value === "string" &&
  //           value.toLowerCase().includes(searchText)
  //       )
  //     );
  //     setGetAllInventory(filteredData);
  //   }
  // };

  const handleFilterStatus = (e) => {
    setStatusSelected(e);

    if (e === "all") {
      setDataCommitment(searchTerm2);
    } else {
      let filteredData;

      if (dataCommitment !== "all") {
        filteredData = searchTerm2.filter(
          (item) =>
            item.itemcategory.toUpperCase() === dataCommitment.toUpperCase()
        );
      } else {
        filteredData = searchTerm2.filter(
          (item) => item.itemcategory.toUpperCase() === e.toUpperCase()
        );
      }
      setDataCommitment(filteredData);
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.pageInfo}>
            <h3 className={styles.pageInfoTitle}>Stock Opname</h3>
            <Breadcrumb
              className={styles.breadcumbs}
              items={[
                {
                  href: "/dashboard",
                  title: <HomeOutlined />,
                },
                {
                  href: "/opname",
                  title: <span>Stock Opname</span>,
                },
              ]}
            />
          </div>
          <div className={styles.wrapperMenu}>
            <div className={styles.wrapperComponent}>
              <div className={styles.searchBox}>
                <Search
                  placeholder="Masukkan kata kunci pencarian"
                  allowClear
                  enterButton="Cari"
                  size="large"
                  onChange={handleChangeSearch}
                  style={{ width: 400 }}
                />
                {/* <Select
                    defaultValue="Semua"
                    style={{
                      width: 250,
                      height: 35,
                    }}
                    // onChange={handleFilterStatus}
                    options={listStatus}
                    placeholder="Filter berdasarkan status"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  ></Select> */}
              </div>
              {(role === "admin" || role === "superadmin") && (
                <div className={styles.tableMenu}>
                  <div className={styles.containerButton}>
                    <div className={styles.buttonMenu1}>
                      <NavLink
                        to="/opname/tambah-stock-opaname"
                        className={styles.btnAddJobs}
                      >
                        Tambah Data
                      </NavLink>
                    </div>
                    <div className={styles.wrapperButton}>
                      <span
                        className={styles.btnAddJobs1}
                        onClick={handledownload}
                      >
                        Export Data
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Table
              className={styles.tableCustomers}
              bordered
              dataSource={mergedData}
              columns={columns}
              pagination={{
                pageSize: 10,
                onChange: (page, pageSize) => {
                  setStartIndex((page - 1) * pageSize + 1);
                },
              }}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Opname;
