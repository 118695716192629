import React, { useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./DetailPengeluaran.module.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
  Tag,
  Tabs,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { useEffect } from "react";
import DeliveryOrder from "../../../DO/DeliveryOrder";
import { BASE_API_URL } from "../../../../constant/url";
import PreviewDO from "../../../DO/PreviewDO";
// import DeliveryOrder from "../../DO/DeliveryOrder";
const { TabPane } = Tabs;
function DetailPengeluaran() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState({});
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") || "preview"
  );
  const [getAllInventory, setGetAllInventory] = useState([]);

  const params = useParams();
  const { id } = params;

  const { Column, ColumnGroup } = Table;

  useEffect(() => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/spendings/detail-spending/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
            message.error("Your session has expired. Please login again.");
          } else if (error.response.status == 404) {
            // Handle 404 error
          } else if (error.response.status === 403) {
            message.error("Access denied");
          } else {
            message.error("Unknown Error");
          }
        } else {
          // Handle other types of errors here
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };

  const inventoryItem = getAllInventory.find(
    (inventory) =>
      String(inventory.id) === String(getAllSpending.inventories_id)
  );
  const inventoryNameToShow = inventoryItem ? inventoryItem.inventoryname : "";

  return (
    <DashboardLayout>
      <div className={styles.wrapperDetail}>
        {/* <p>Detail Pengeluaran</p> */}
        <h3 className={styles.titleWrapper}>Detail Pengeluaran</h3>
        <div className={styles.buttonMenu}>
          {/* <NavLink
          to={`/pengeluaran/detail-pengeluaran/add-detail-pengeluaran`}
          className={styles.btnAddCustomer}
        >
          Buat DO
        </NavLink> */}
        </div>
        <div className={styles.mainDetail}>
          <div className={styles.mainUserDetail}>
            <div className={styles.mainDetailData}>
              <div className={styles.mainLeftData}>
                <p className={styles.titleDetail}>
                  PT. KALTIM INDUSTRIAL ESTATE
                </p>
              </div>
            </div>
            <div className={styles.mainDetailData}>
              <div className={styles.mainLeftData}>
                <p className={styles.titleDetail}>Deskripsi</p>
              </div>
            </div>
            <div className={styles.mainDetailData}>
              <div className={styles.mainLeftData}>
                <p className={styles.titleDetail}>Nama Barang Keluar</p>
              </div>
              <div className={styles.mainRightData}>
                <p className={styles.textDetail}>: {inventoryNameToShow}</p>
              </div>
            </div>
            <div className={styles.mainDetailData}>
              <div className={styles.mainLeftData}>
                <p className={styles.titleDetail}>Lokasi</p>
              </div>
              <div className={styles.mainRightData}>
                <p className={styles.textDetail}>
                  : {getAllSpending.locationspendings}
                </p>
              </div>
            </div>
            <div className={styles.mainDetailData}>
              <div className={styles.mainLeftData}>
                <p className={styles.titleDetail}>Jumlah Pengeluaran</p>
              </div>
              <div className={styles.mainRightData}>
                <p className={styles.textDetail}>
                  : {(() => {
                    const formattedTotal = parseFloat(getAllSpending.totalspendings).toLocaleString();
                    return <span>{formattedTotal}</span>;
                  })()} {""}
                  {getAllSpending.unitspendings}
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
          {/* <TabPane tab="Delivery Order" key="do">
            <DeliveryOrder />
          </TabPane>{" "} */}
          <TabPane tab="Preview DO" key="preview">
            <PreviewDO />
          </TabPane>
        </Tabs>
      </div>
    </DashboardLayout>
  );
}

export default DetailPengeluaran;
