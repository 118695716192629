import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { Skeleton, Breadcrumb, Tabs } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import styles from "./MasterPersediaan.module.css";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import Persediaan from "../ListPersediaan/Persediaan";
import PersediaanProyek from "../ListPersediaanProyek/PersediaanProyek";

const { TabPane } = Tabs;

function MasterPersediaan() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(
        new URLSearchParams(location.search).get("tab") || "persediaan" || "ex-proyek"
    );

    const handleTabChange = (key) => {
        setActiveTab(key);
        navigate(`?tab=${key}`);
    };

    return (
        <DashboardLayout>
            {/* {loading ? (
        <Skeleton />
      ) : ( */}
            <div className={styles.wrapper}>
                <div className={styles.topWrapper}>
                    <h2 className={styles.pageTitle}>Persediaan Barang</h2>
                    <Breadcrumb
                        className={styles.breadcumbs}
                        items={[
                            {
                                href: "/dashboard",
                                title: <HomeOutlined />,
                            },
                            {
                                href: "/persediaan",
                                title: <span>Persediaan Barang</span>,
                            },
                        ]}
                    />
                </div>
                <div className={styles.main}>
                    {/* <div className={styles.pageInfo}>
                        <h3 className={styles.pageInfoTitle}>List Akun</h3>
                        <p className={styles.pageInfoDecsription}>
                            Daftar akun yang telah terdaftar di aplikasi
                        </p>
                    </div> */}

                    <div className={styles.content}>
                        <Tabs type="card" activeKey={activeTab} onChange={handleTabChange}>
                            <TabPane tab="Persediaan Gudang" key="persediaan">
                                <Persediaan />
                            </TabPane>
                            <TabPane tab="Persediaan Ex-Proyek" key="ex-proyek">
                                <PersediaanProyek />
                            </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
            {/* )} */}
        </DashboardLayout>
    );
}

export default MasterPersediaan;
