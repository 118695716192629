import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./ListKategoriBarang.module.css";
import { message, DatePicker, Table, Modal, Tooltip, Breadcrumb } from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../../constant/url";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import Search from "antd/es/input/Search";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;
function ListKategoriBarang() {
  const [loading, setLoading] = useState(false);
  const [getAllLeave, setGetAllLeave] = useState([]);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [getAllListBarangRegister, setGetAllListBarangRegister] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  const formatRupiah = (value) => {
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },

    {
      title: "Kategori Barang",
      dataIndex: "itemcategory",
      key: "itemcategory",
    },

    {
      title: "Action",
      key: "aksi",
      width: "10%",
      render: (_, record) => {
        if (getAllListBarangRegister.length >= 1) {
          return (
            <div className={styles.btngroup}>
              <NavLink
                to={`/kategori-barang/edit-kategori/${record.id}`}
                className={styles.btnEditCustomer}
              >
                <AiFillEdit />
              </NavLink>
              {/* <button
                className={styles.btnDeleteAdmin}
                onClick={() => deleteItems(record.id)}
              >
                <AiFillDelete />
              </button> */}
            </div>
          );
        }
      },
    },
  ];

  console.log("kolom", columns);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepart/list-sparepart-category`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListBarangRegister(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const deleteItems = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/sparepart/deletesparepart/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Items successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  const handleChangeSearch = (e) => {
    console.log(e);
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllListBarangRegister(searchTerm);
    } else {
      const filteredData = searchTerm.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchText)
        )
      );
      setGetAllListBarangRegister(filteredData);
    }
  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.wrapperMenu}>
          <div className={styles.wrapperButtonMenu}>
            <Search
              placeholder="Masukkan kata kunci pencarian"
              allowClear
              enterButton="Cari"
              size="large"
              onChange={handleChangeSearch}
              style={{ width: 400 }}
            />
            <div className={styles.buttonMenu1}>
              <NavLink
                to="/kategori-barang/tambah-kategori"
                className={styles.btnAddJobs}
              >
                Tambah Kategori
              </NavLink>
            </div>
          </div>
          <Table
            className={styles.tableCustomers}
            bordered
            dataSource={getAllListBarangRegister}
            columns={columns}
            pagination={{
              pageSize: 10,
              onChange: (page, pageSize) => {
                setStartIndex((page - 1) * pageSize + 1);
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ListKategoriBarang;
