import { Alert, Input, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import styles from "./Login.module.css";
import ReactLoading from "react-loading";
import { imageLogin } from "../../assets";
import { BASE_API_URL } from "../../constant/url";
function Login(props) {
  const [openAlert, setOpenAlert] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState({
    NPK: null,
    password: null,
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // const handleLogin = (e) => {
  //   console.log(user);
  //   if (user.email === "user@gmail.com" && user.password === "password") {
  //     localStorage.setItem("role", "user");
  //     localStorage.setItem("accessToken", Math.random());
  //     navigate("/dashboard");
  //   } else if (
  //     user.email === "admin@gmail.com" &&
  //     user.password === "password"
  //   ) {
  //     localStorage.setItem("role", "admin");
  //     localStorage.setItem("accessToken", Math.random());
  //     navigate("/dashboard");
  //   } else if (
  //     user.email === "superadmin@gmail.com" &&
  //     user.password === "password"
  //   ) {
  //     localStorage.setItem("role", "super-admin");
  //     localStorage.setItem("accessToken", Math.random());
  //     navigate("dashboard");
  //   } else {
  //     alert("email/password salah");
  //   }
  // };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setLoadingLogin(true);
    try {
      const response = await axios.post(`${BASE_API_URL}/v1/auth/login`, user);

      if (response && response.data) {
        const user = response.data.data.user;
        const accessToken = response.data.data.accessToken;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("username", JSON.stringify(response.data));
        const role = response.data.data.role;
        // const name = response.data.data.user.name;
        if (accessToken) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("user", JSON.stringify(user));
          if (role === "admin") {
            localStorage.setItem("role", "admin");
            navigate("/dashboard");
          } else if (role === "user") {
            localStorage.setItem("role", "user");
            navigate("/dashboard");
          } else if (role === "superadmin") {
            localStorage.setItem("role", "superadmin");
            navigate("/dashboard");
          }
        } else {
          message.error(`Response tidak memiliki akses token`);
        }
      } else {
        message.error(`Login gagal, respons nol atau tidak berisi data.`);
      }
    } catch (error) {
      setLoadingLogin(false);
      if (error.response) {
        if (error.response.data.statusCode === 4003) {
          setOpenAlert(true);
          setMessageVal("Salah role");
        } else if (error.response.data.statusCode === 4002) {
          setOpenAlert(true);
          setMessageVal("Kata sandi anda salah");
        } else if (error.response.data.statusCode === 404) {
          setOpenAlert(true);
          setMessageVal(
            "Akun anda tidak terdaftar, hubungi pusat bantuan untuk pembuatan akun"
          );
        } else if (error.response.data) {
          setOpenAlert(true);
          setMessageVal(`${error.response.data.message}`, 3);
        } else {
          message.error(
            `Error ${error.response.status}: Kesalahan yang tidak diketahui`,
            3
          );
        }
      } else if (error.message) {
        message.error(`Error: ${error.message}`, 3);
      } else {
        message.error(`Kesalahan yang tidak diketahui.`);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          <img src={imageLogin} alt="image login" className={styles.leftImg} />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.formGroup}>
            <h3 className={styles.formTitle}>Login</h3>
            {/* alert */}
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>

            <div className={styles.formField}>
              <label htmlFor="NPK" className={styles.label}>
                NPK
              </label>
              <Input
                required
                type="NPK"
                className={styles.input}
                name="NPK"
                value={user.NPK}
                onChange={handleChange}
              />
              {/* <p className={styles.detailInput}>Example: email@gmail.com</p> */}
            </div>
            <div className={styles.formField}>
              <label htmlFor="password" className={styles.label}>
                Password
              </label>
              <Input.Password
                required
                name="password"
                className={styles.input}
                value={user.password}
                onChange={handleChange}
                iconRender={(visible) =>
                  visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div className={styles.infoLogin}></div>
            {loadingLogin ? (
              <button disabled className={styles.btnLogin}>
                <ReactLoading
                  className={styles.loadingLogin}
                  type={props.balls}
                  color={props.color}
                  height={20}
                  width={30}
                />
              </button>
            ) : (
              <button className={styles.btnLogin} onClick={handleLogin}>
                Masuk
              </button>
            )}
            {/* <button className={styles.btnLogin} onClick={handleLogin}>
              Login
            </button> */}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.footerComp}>PT. Kaltim Industrial Estate</p>
      </div>
    </div>
  );
}

export default Login;
