import React, { useState } from "react";
import styles from "./PengeluaranPasir.module.css";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
  Tabs,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { useEffect } from "react";
import { BASE_API_URL } from "../../../../../constant/url";
import DashboardLayout from "../../../../../layouts/dashboard-layout/DashboardLayout";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;
const { TabPane } = Tabs;

function PengeluaranPasir() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get("tab") ||
      "pasir" ||
      "bbm" ||
      "sipil" ||
      "electrical" ||
      "mechanical" ||
      "batu" ||
      "semen" ||
      "wtp" ||
      "ipal"
  );
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const [getAllInventory, setGetAllInventory] = useState([]);

  const columns = [
    {
      title: "No",
      width: "10%",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "No Pengeluaran",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Tanggal Pengeluaran",
      dataIndex: "datepasir",
      key: "datepasir",
      render: (startDate) => {
        const formattedDate = new Date(startDate).toLocaleDateString();
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Departemen",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Posisi",
      dataIndex: "posisi",
      key: "posisi",
    },
    {
      title: "Nama Barang",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    {
      title: "Jumlah Pengeluaran",
      dataIndex: "jumlahpasir",
      key: "jumlahpasir",
    },
    {
      title: "Satuan",
      dataIndex: "satuanpasir",
      key: "satuanpasir",
    },
    {
      title: "Transport",
      dataIndex: "transport",
      key: "transport",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Diterima",
      dataIndex: "diterima",
      key: "diterima",
    },
    {
      title: "Diserahkan",
      dataIndex: "diserahkan",
      key: "diserahkan",
    },

    // {
    //   title: "Action",
    //   width: "10%",
    //   key: "action",
    //   render: (_, record) => (
    //     <div className={styles.wrapperIcon}>
    //       <Space size="middle">
    //         <NavLink to={`/pengeluaran/detail-pengeluaran/`}>
    //           <Tooltip
    //             placement="bottom"
    //             title={`Klik untuk lihat dan edit skoring`}
    //           >
    //             <AiFillEye className={styles.iconActionView} />
    //           </Tooltip>
    //         </NavLink>
    //         <button
    //           className={styles.btnDeleteAdmin}
    //           onClick={() => deleteSpending(record.id)}
    //         >
    //           <AiFillDelete />
    //         </button>
    //       </Space>
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/pengeluaranpasir/list-pengeluaran-pasir`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List Inventories
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const deleteSpending = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/spendings/deletespending/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Inventory successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() {},
    });
  };

  const mergedData = getAllSpending.map((spendingItem) => {
    const inventoryItem = getAllInventory.find(
      (inventory) =>
        String(inventory.id) === String(spendingItem.inventories_id)
    );

    return {
      ...spendingItem,
      inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
    };
  });

  console.log("mergedata", mergedData);

  const handleTabChange = (key) => {
    setActiveTab(key);
    navigate(`?tab=${key}`);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.pageInfo}></div>

        <div className={styles.buttonMenu}>
          <NavLink
            to="/pengeluaran/add-pengeluaran-pasir"
            className={styles.btnAddCustomer}
          >
            Add Data
          </NavLink>
        </div>

        <Table
          className={styles.tableCustomers}
          bordered
          dataSource={mergedData}
          columns={columns}
        />
      </div>
    </div>
  );
}

export default PengeluaranPasir;
